import '../css/flipbook_mobile_subscriber_login_page.css';
import 'jquery-migrate';
import $ from 'jquery';

$.browser = {};
window.$ = window.$j = window.jQuery = $; // eslint-disable-line no-multi-assign
require('./flipbook3/jquery/jquery.form-latest');

const empty = /^\s*$/;

function init({
  g_webroot,
  g_titleId,
  g_titleName,
  subscriberId,
  showPopup,
  myAccountUrl,
  subscribeUrl,
}) {
  function loadModal(title, url) {
    $('#TB_ajaxContent').html("<div class='progress_standalone'>loading...</div>").load(url);
  }

  function forgotCredentials(e) {
    e.preventDefault();

    if (empty.test($('#forgot_creds_email').val())) {
      $('#forgot_creds_email').focus();
      return false;
    }

    $('#forgot_creds_send').val('Please wait...').attr('disabled', 'disabled');

    const postData = {
      email: $('#forgot_creds_email').val().trim(),
      title_id: g_titleId,
      title_name: g_titleName,
    };
    /* eslint-disable sort-keys */
    $.ajax({
      type: 'POST',
      data: postData,
      url: `${g_webroot}subscribers/ajax_forgot_credentials/`,
    }).done((data) => {
      if (data === 1) {
        alert(
          'Thank you. Please check your email for your credentials and then try logging in again.',
        );
        $('.forgot_creds_toggle').slideToggle();
      } else {
        let msg = data;
        if (data === 0) {
          msg = 'Sorry, your email was not recognized. Please try again.';
        }

        alert(msg);

        $('#forgot_creds_email').focus();
      }
      $('#forgot_creds_send').val('send').attr('disabled', null);
    });
    /* eslint-enable sort-keys */
    return false;
  }

  $(document).ready(() => {
    if (subscriberId > 1) {
      loadModal('My Account', myAccountUrl);
    } else if (showPopup) {
      loadModal('Subscribe', subscribeUrl);
    }

    $('.js-forgot-credentials-form').on('submit', forgotCredentials);
    $('.js-slide-forgotCreds').on('click', () => $('.forgot_creds_toggle').slideToggle());
    $('.js-subcribe').on('click', function handleClick() {
      loadModal(this.dataset.subscribeTitle, this.dataset.subscribeUrl);
    });
  });
}

export { init };
